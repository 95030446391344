import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import VM from 'scratch-vm';
import {connect} from 'react-redux';
import {CodeMirror} from 'code-editor';

import {STAGE_DISPLAY_SIZES} from '../lib/layout-constants';

import StageBoardComponent from '../components/stage-board/stage-board.jsx';

class StageBoard extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleProjectChanged',
        ]);

        if (this.props.vm.codeViewer) {
            this.editor = this.props.vm.codeViewer;
            this.canvas = this.editor.getParent();
        } else {
            this.canvas = document.createElement('div');
            this.editor = new CodeMirror(this.canvas);
            this.props.vm.attachCodeViewer(this.editor);
        }

    }
    componentDidMount () {
        this.props.vm.refreshCodeViewer();
        this.props.vm.addListener('PROJECT_CHANGED', this.handleProjectChanged);
    }
    componentWillUnmount () {
        this.props.vm.removeListener('PROJECT_CHANGED', this.handleProjectChanged);
    }
    handleProjectChanged (){
        if (this.props.vm.codeViewer) {
            this.props.vm.refreshCodeViewer();
        }
    }
    render () {
        const {
            ...props
        } = this.props;
        return (
            <StageBoardComponent
                canvas={this.canvas}
                isFullScreen={this.props.isFullScreen}
                stageSize={this.props.stageSize}
                {...props}
            />
        );
    }
}


StageBoard.propTypes = {
    isFullScreen: PropTypes.bool.isRequired,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapStateToProps = state => ({
    isFullScreen: state.scratchGui.mode.isFullScreen,
});

export default connect(
    mapStateToProps,
)(StageBoard);