import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';

import { Card, List } from 'antd';

import UNOImage from './img--uno.png';
import NANOImage from './img--nano.png';

import styles from './board-modal.css';

import boardModalMessages from './board-modal-messages';

const BoardModalComponent = props => {
    const data = [
        {
            title: 'Arduino UNO',
            image: UNOImage,
            onClick: props.onSetArduinoUNO,
        },
        {
            title: 'Arduino NANO',
            image: NANOImage,
            onClick: props.onSetArduinoNANO,
        },
    ];

    return (
        <Modal
            id="boardModal"
            className={styles.modalContent}
            headerClassName={styles.header}
            contentLabel={props.intl.formatMessage(boardModalMessages.title)}
            onRequestClose={props.onCancel}
        >
            <Box className={styles.body}>
                <List
                    className={styles.boardList}
                    grid={{ gutter: 16, column: 2 }}
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item className={styles.boardListItem} onClick={()=>{
                            item.onClick();
                            props.showSnackBar(props.intl.formatMessage(boardModalMessages.snackBarMessage, {board: item.title}));

                        }}>
                            <Card
                                hoverable
                                title={item.title}
                                size='small'
                                bordered={false}
                                cover={<img alt={item.title} src={item.image} className={styles.boardImage} draggable="false"/>}
                                className={styles.boardCard}
                            />
                        </List.Item>
                    )}
                />
            </Box>
        </Modal>
    )
};

BoardModalComponent.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSetArduinoUNO: PropTypes.func.isRequired,
    onSetArduinoNANO: PropTypes.func.isRequired,
    showSnackBar: PropTypes.func.isRequired,
    intl: intlShape,
};

export default injectIntl(BoardModalComponent);