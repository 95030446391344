import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import VM from 'scratch-vm';

import ExampleModalComponent from '../components/example-modal/example-modal.jsx';

import exampleLibraryContent from '../lib/libraries/examples/index.jsx';

import {openLoadingProject, closeLoadingProject, closeExampleLibrary} from '../reducers/modals';

import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import { enqueueSnackbar, closeSnackbar } from 'notistack';

import {
    EDITOR_TAB_INDEX
} from '../reducers/editor-tab';

const enc = new TextDecoder("utf-8");
const messages = {
    success: <FormattedMessage
        defaultMessage="Loaded example successfully"
        description="Message to show when example is loaded successfully"
        id="gui.exampleModal.snackbarSuccess"
    />,
    error: <FormattedMessage
        defaultMessage="Failed to load example"
        description="Message to show when example is failed to load"
        id="gui.exampleModal.snackbarError"
    />,
};

class ExampleModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'clearReader',
            'handleReaderOnloadSB3',
            'handleReaderOnloadINO',
            'handleExampleSelecton',
            'showSnackBar',
        ]);

        this.snackbarId = null;
    }

    clearReader () {
        this.reader = null;
    }

    handleReaderOnloadSB3 () {
        if (this.reader) {
            this.props.vm.loadProject(this.reader.result)
                .then(() => {
                    if (!this.props.scratchMode) this.props.vm.refreshCodeViewer();
                    this.showSnackBar(messages.success, 'success');
                })
                .catch(err => {
                    console.error(`Failed to load example. ${err.message}`);
                    this.showSnackBar(messages.error, 'error');
                })
                .finally(() => {
                    this.clearReader();
                });
        }
    }

    handleReaderOnloadINO () {
        if (this.reader) {
            this.props.vm.emit('UPDATE_CODE_EDITOR', enc.decode(this.reader.result));
            this.showSnackBar(messages.success, 'success');
            this.clearReader();
        }
    }

    handleExampleSelecton (urlId, type) {
        this.props.onCancel();
        this.props.onLoadingStarted();
        fetch(`static/examples/${urlId}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP Status Code: ${response.status}`);
                }
                return response.blob();
            }).then(blob => {
                this.reader = new FileReader();
                this.reader.onload = type === 'block' ? this.handleReaderOnloadSB3 : this.handleReaderOnloadINO;
                this.reader.readAsArrayBuffer(blob);
            })
            .catch(err => {
                console.error(`Failed to load ${urlId}. ${err.message}`);
                this.showSnackBar(messages.error, 'error');
            }).finally(() => {
                this.props.onLoadingFinished();
            });
    }

    showSnackBar (message, variant) {
        if (this.snackbarId) closeSnackbar(this.snackbarId);
        this.snackbarId = enqueueSnackbar(message, {
            variant: variant,
            autoHideDuration: 1500,
        });
    }

    render () {
        const showCodeExample = !this.props.scratchMode && this.props.getIsEditorTab;
        const exampleLibraryContentData = Object.keys(exampleLibraryContent)
            .filter(id => {
                const exampleData = exampleLibraryContent[id];

                // only show relevant examples based on the current mode
                if (exampleData.type !== 'code') {
                    return showCodeExample ? false : true;
                } else {
                    return showCodeExample ? true : false;
                }
            })
            .map(id => ({
                id: id,
                name: exampleLibraryContent[id].name,
                tags: exampleLibraryContent[id].tags,
                type: exampleLibraryContent[id].type,
                urlId: exampleLibraryContent[id].urlId,
                hidden: exampleLibraryContent[id].hidden || false,
                img: exampleLibraryContent[id].img,
            }));

        return (
            <ExampleModalComponent 
                data={exampleLibraryContentData}
                onCancel={this.props.onCancel}
                onChooseExample={this.handleExampleSelecton}
            />
        );
    }
}

ExampleModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
    // intl: intlShape.isRequired,
    scratchMode: PropTypes.bool,
    getIsEditorTab: PropTypes.bool,
}

const mapStateToProps = state => ({
    scratchMode: state.scratchGui.vmStatus.scratch_mode,
    getIsEditorTab: state.scratchGui.editorTab.activeTabIndex === EDITOR_TAB_INDEX,
});

const mapDispatchToProps = dispatch => {
    return {
        onCancel: () => dispatch(closeExampleLibrary()),
        onLoadingStarted: () => dispatch(openLoadingProject()),
        onLoadingFinished: () => dispatch(closeLoadingProject()),
    }
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(ExampleModal);