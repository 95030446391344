import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import languageIcon from './icon--language.svg';
import dropdownCaret from '../menu-bar/dropdown-caret.svg';

import menuBarStyles from '../menu-bar/menu-bar.css';

import LanguageMenuItems from './language-menu-item.jsx';

const LanguageMenu = ({
    isRtl,
    isLanguageMenuOpen,
    openMenu,
    closeMenu,
}) => (
    <div
        className={classNames(menuBarStyles.menuBarItem, menuBarStyles.hoverable, menuBarStyles.themeMenu, {
            [menuBarStyles.active]: isLanguageMenuOpen
        })}
        onMouseUp={openMenu}
    >
        <img src={languageIcon} draggable={false}/>
        <span style={{margin: '0 0.25rem'}}></span>
        <img src={dropdownCaret} draggable={false}/>
        <LanguageMenuItems 
            isRtl={isRtl}
            isMenuOpen={isLanguageMenuOpen}
            closeMenu={closeMenu}
        />
    </div>
);

LanguageMenu.propTypes = {
    isRtl: PropTypes.bool,
    isLanguageMenuOpen: PropTypes.bool,
    openMenu: PropTypes.func,
    closeMenu: PropTypes.func,
};

export default LanguageMenu;