import React from 'react';
import {FormattedMessage} from 'react-intl';

import thumbnailSample from './thumbnails/325x200.svg';

export default {
    'test-block': {
        name: (
            <FormattedMessage
                defaultMessage="Test Block"
                description="Test Block"
                id="gui.examples.test-block"
            />
        ),
        type: 'block',
        tags: [],
        urlId: 'testblock.sb3',
        img: thumbnailSample,
    },
    'test-code': {
        name: (
            <FormattedMessage
                defaultMessage="Test Code"
                description="Test Code"
                id="gui.examples.test-code"
            />
        ),
        type: 'code',
        tags: [],
        urlId: 'testcode.ino',
        img: thumbnailSample,
    }
};