import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage, defineMessages, injectIntl, intlShape} from 'react-intl';

import DOMElementRenderer from '../../containers/dom-element-renderer.jsx';

import styles from './console.css';

const ConsoleComponent = ({
    canvas,
    expanded,
    onToggle,
    disabled,
}) => (
    <div className={styles.consoleContainer}>
        <div
            className={styles.consoleHeader}
            onClick={!disabled ? onToggle : null}
        >
            {!disabled && onToggle ? (
                <FormattedMessage
                    defaultMessage="Console"
                    description="Button to open the console"
                    id="gui.console.header"
                />
            ) : 
                null
            }
        </div>
        {!disabled && expanded ? (
            <div className={classNames(styles.consoleContent)}>
                <div className={styles.consoleContentInner}>
                    <DOMElementRenderer
                        domElement={canvas}
                        style={{
                            width: '100%',
                            height: '100%',

                        }}
                        containerStyle={{
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </div>
            </div>
        ) : null}
    </div>
);

ConsoleComponent.propTypes = {
    canvas: PropTypes.object.isRequired,
    expanded: PropTypes.bool,
    onToggle: PropTypes.func,
    disabled: PropTypes.bool,
};

ConsoleComponent.defaultProps = {
    expanded: false,
    onToggle: null,
    disabled: false,
};

export default injectIntl(ConsoleComponent);
