import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'gui.boardModal.title',
        defaultMessage: 'Select a Board',
        description: 'Title for the board selection modal'
    },
    snackBarTitle: {
        id: 'gui.boardModal.selectedSnackBarTitle',
        defaultMessage: 'Board Selection',
        description: 'Title for the snackbar when a board is selected'
    },
    snackBarMessage: {
        id: 'gui.boardModal.selectedSnackBarMessage',
        defaultMessage: '{board} is selected',
        description: 'Message for the snackbar when a board is selected'
    },
});

