exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* looks-secondary */ /* 35% transparent version of looks-secondary */ /* 15% transparent version of looks-secondary */ /* looks-secondary-dark */ /* blue set */ /* purple set */ /* $looks-secondary: hsla(260, 60%, 60%, 1);  */ /* $looks-transparent: hsla(260, 60%, 60%, 0.35);  */ /* $looks-light-transparent: hsla(260, 60%, 60%, 0.15);  */ /* $looks-secondary-dark: \thsla(260, 42%, 51%, 1);  */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #0B8E69 */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ /* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */ /* layout contants from `layout-constants.js` */ .example-modal_modal-content_3okrl {\n    width: 700px;\n    line-height: 1.75;\n} .example-modal_header_2DKOT {\n    background-color: hsla(163, 86%, 30%, 1);\n} .example-modal_body_3KKpl {\n    padding: 12px;\n    background: hsla(0, 100%, 100%, 1);\n} .example-modal_container_3qC4U {\n    background-color: rgb(240, 242, 245);\n    border-radius: 8px;\n    padding: 1rem;\n}", ""]);

// exports
exports.locals = {
	"modal-content": "example-modal_modal-content_3okrl",
	"modalContent": "example-modal_modal-content_3okrl",
	"header": "example-modal_header_2DKOT",
	"body": "example-modal_body_3KKpl",
	"container": "example-modal_container_3qC4U"
};