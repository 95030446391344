import {defineMessages} from 'react-intl';

export default defineMessages({
    blueinnoStudent: {
        id: 'gui.menuBar.blueinnoStudent',
        defaultMessage: 'blueinno student',
        description: 'Menu bar item for blueinno student'
    },
    board: {
        id: 'gui.menuBar.board',
        defaultMessage: 'Board',
        description: 'Text for board modal'
    },
    convertProject: {
        id: 'gui.menuBar.convertProject',
        defaultMessage: 'Convert Blocks to Codes',
        description: 'Menu bar item for convert project (block to code)'
    },
    convertProjectDialogTitle: {
        id: 'gui.menuBar.convertProjectDialogTitle',
        defaultMessage: 'Warning',
        description: 'Dialog title for convert project (block to code)'
    },
    convertProjectDialogContent: {
        id: 'gui.menuBar.convertProjectDialogContent',
        defaultMessage: 'It is about to convert the Block code to the Editor code. Are you sure?',
        description: 'Dialog content for convert project (block to code)'
    },
    createCopy: {
        id: 'gui.menuBar.saveAsCopy',
        defaultMessage: 'Save as a copy',
        description: 'Menu bar item for saving as a copy'
    },
    downloadIsReady: {
        id: 'gui.menuBar.downloadIsReady',
        defaultMessage: 'Download is ready',
        description: 'Menu bar item for downloading a project to your computer'
    },
    edit: {
        id: 'gui.menuBar.edit',
        defaultMessage: 'Edit',
        description: 'Text for edit dropdown menu'
    },
    examples: {
        id: 'gui.menuBar.examples',
        defaultMessage: 'Examples',
        description: 'Text for examples dropdown menu'
    },
    file: {
        id: 'gui.menuBar.file',
        defaultMessage: 'File',
        description: 'Text for file dropdown menu'
    },
    format: {
        id: 'gui.menuBar.format',
        defaultMessage: 'Format',
        description: 'Text for format dropdown menu'
    },
    modeMenu: {
        id: 'gui.menuBar.modeMenu',
        defaultMessage: 'Mode',
        description: 'Mode menu item in the menu bar'
    },
    modeMenuCaturday: {
        id: 'gui.menuBar.caturdayMode',
        defaultMessage: 'Caturday mode',
        description: 'April fools: Cat blocks mode'
    },
    modeMenuNormal: {
        id: 'gui.menuBar.normalMode',
        defaultMessage: 'Normal mode',
        description: 'April fools: resets editor to not have any pranks'
    },
    newProject: {
        id: 'gui.menuBar.new',
        defaultMessage: 'New',
        description: 'Menu bar item for creating a new project'
    },
    pair: {
        id: 'gui.menuBar.pair',
        defaultMessage: 'Pair',
        description: 'Menu bar item for pair'
    },
    pairing: {
        id: 'gui.menuBar.pairing',
        defaultMessage: 'Pairing the board ...',
        description: 'Message for pairing the board'
    },
    paired: {
        id: 'gui.menuBar.paired',
        defaultMessage: 'Board is paired',
        description: 'Message for paired the board'
    },
    pairFailed: {
        id: 'gui.menuBar.pairFailed',
        defaultMessage: 'Failed to pair the board',
        description: 'Message for pair failed'
    },
    redo: {
        id: 'gui.menuBar.redo',
        defaultMessage: 'Redo',
        description: 'Menu bar item for redoing the previous action'
    },
    remix: {
        id: 'gui.menuBar.remix',
        defaultMessage: 'Remix',
        description: 'Menu bar item for remixing'
    },
    turnOffTurboMode: {
        id: 'gui.menuBar.turboModeOff',
        defaultMessage: 'Turn off Turbo Mode',
        description: 'Menu bar item for turning off turbo mode'
    },
    turnOnTurboMode: {
        id: 'gui.menuBar.turboModeOn',
        defaultMessage: 'Turn on Turbo Mode',
        description: 'Menu bar item for turning on turbo mode'
    },
    saveNow: {
        id: 'gui.menuBar.saveNow',
        defaultMessage: 'Save now',
        description: 'Menu bar item for saving now'
    },
    undo: {
        id: 'gui.menuBar.undo',
        defaultMessage: 'Undo',
        description: 'Menu bar item for undoing the previous action'
    },
    uploading: {
        id: 'gui.menuBar.uploading',
        defaultMessage: 'Uploading ...',
        description: 'Menu bar item for uploading a project from your computer'
    },
});