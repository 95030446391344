import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {FormattedMessage} from 'react-intl';

export default function AlertDialog({open, title, content, handleClickContinue, handleClickCancel}) {
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClickCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {content}
                        </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={handleClickContinue} color="primary">
                        <FormattedMessage
                            defaultMessage="Continue"
                            description="Button to continue with the action"
                            id="gui.dialog.continue"
                        />
                    </Button>
                    <Button onClick={handleClickCancel} color="primary">
                        <FormattedMessage
                            defaultMessage="Cancel"
                            description="Button to cancel the action"
                            id="gui.dialog.cancel"  
                        />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}