import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, injectIntl, intlShape} from 'react-intl';

import GreenFlag from '../green-flag/green-flag.jsx';
import StopAll from '../stop-all/stop-all.jsx';
import TurboMode from '../turbo-mode/turbo-mode.jsx';

import UploadButtonComponent from '../upload-button/upload-button.jsx';
import VerifyButtonComponent from '../verify-button/verify-button.jsx';

import styles from './controls.css';
import { connect } from 'react-redux';
import { EDITOR_TAB_INDEX } from '../../reducers/editor-tab';

import AlertDialog from '../dialog/dialog.jsx';

const messages = defineMessages({
    goTitle: {
        id: 'gui.controls.go',
        defaultMessage: 'Go',
        description: 'Green flag button title'
    },
    stopTitle: {
        id: 'gui.controls.stop',
        defaultMessage: 'Stop',
        description: 'Stop button title'
    },
    uploadTitle: {
        id: 'gui.controls.upload',
        defaultMessage: 'Upload',
        description: 'Upload button title'
    },
    verifyTitle: {
        id: 'gui.controls.verify',
        defaultMessage: 'Verify',
        description: 'Verify button title'
    }
});

const Controls = function (props) {
    const {
        active,
        className,
        intl,
        onGreenFlagClick,
        onUploadClick,
        onStopAllClick,
        onVerifyClick,
        turbo,
        scratchMode,
        dispatch,
        verifying,
        flashing,
        disableFlash,
        getIsEditorTab,
        ...componentProps
    } = props;
    const [openDialog, setOpenDialog] = React.useState(false);

    return (
        <div
            className={classNames(styles.controlsContainer, className)}
            {...componentProps}
        >
            {scratchMode ?
            <GreenFlag
                active={active}
                title={intl.formatMessage(messages.goTitle)}
                onClick={onGreenFlagClick}
            /> :
            <UploadButtonComponent 
                active={flashing}
                title={intl.formatMessage(messages.uploadTitle)}
                onClick={onUploadClick}
                disabled={disableFlash}
            />}

            {scratchMode ?
            <StopAll
                active={active}
                title={intl.formatMessage(messages.stopTitle)}
                onClick={onStopAllClick}
            /> : null}

            {!scratchMode ?
            <VerifyButtonComponent
                active={verifying}
                title={intl.formatMessage(messages.verifyTitle)}
                onClick={onVerifyClick}
            /> : null}

            {turbo ? (
                <TurboMode />
            ) : null}
        </div>
    );
};

Controls.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    intl: intlShape.isRequired,
    onGreenFlagClick: PropTypes.func.isRequired,
    onUploadClick: PropTypes.func.isRequired,
    onVerifyClick: PropTypes.func.isRequired,
    onStopAllClick: PropTypes.func.isRequired,
    turbo: PropTypes.bool,
    scratchMode: PropTypes.bool,
    getIsEditorTab: PropTypes.bool,
    verifying: PropTypes.bool,
    flashing: PropTypes.bool,
    disableFlash: PropTypes.bool,
};

Controls.defaultProps = {
    active: false,
    turbo: false,
    verifying: false,
    flashing: false,
    disableFlash: false,
};

const mapStateToProps = state => ({
    scratchMode: state.scratchGui.vmStatus.scratch_mode,
    getIsEditorTab: state.scratchGui.editorTab.activeTabIndex === EDITOR_TAB_INDEX
});

export default injectIntl(connect(
    mapStateToProps,
)(Controls));
