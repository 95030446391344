import {defineMessages} from 'react-intl';

export default defineMessages({
    connect: {
        id: 'gui.serialMonitor.connect',
        defaultMessage: 'Connect',
        description: 'Button to start connecting serial monitor to a specific device'
    },
    connecting: {
        id: 'gui.serialMonitor.connecting',
        defaultMessage: 'Connecting',
        description: 'Label indicating that the serial monitor is in the process of connecting to a device'
    },
    connected: {
        id: 'gui.serialMonitor.connected',
        defaultMessage: 'Connected',
        description: 'Label indicating that the serial monitor is connected to a device'
    },
    disconnect: {
        id: 'gui.serialMonitor.disconnect',
        defaultMessage: 'Disconnect',
        description: 'Button to disconnect serial monitor from a device'
    },
    disconnecting: {
        id: 'gui.serialMonitor.disconnecting',
        defaultMessage: 'Disconnecting',
        description: 'Label indicating that the serial monitor is in the process of disconnecting from a device'
    },
    connectingSnackBar: {
        id: 'gui.serialMonitor.connectingSnackBar',
        defaultMessage: 'Connecting to Serial Monitor...',
        description: 'Message shown in snackbar when connecting to a device'
    },
    connectedSnackBar: {
        id: 'gui.serialMonitor.connectedSnackBar',
        defaultMessage: 'Serial Monitor connected',
        description: 'Message shown in snackbar when connected to a device'
    },
    connectionFailedSnackBar: {
        id: 'gui.serialMonitor.connectionFailedSnackBar',
        defaultMessage: 'Failed to connect Serial Monitor',
        description: 'Message shown in snackbar when failed to connect to a device'
    },
    disconnectedSnackBar: {
        id: 'gui.serialMonitor.disconnectedSnackBar',
        defaultMessage: 'Serial Monitor disconnected',
        description: 'Message shown in snackbar when disconnected from a device'
    },
    inputPlaceholder: {
        id: 'gui.serialMonitor.inputPlaceholder',
        defaultMessage: 'Send Characters',
        description: 'Serial monitor input placeholder'
    },
    baud: {
        id: 'gui.serialMonitor.baud',
        defaultMessage: 'baud',
        description: 'Serial monitor baud rate label'
    },
    baudWarningTitle: {
        id: 'gui.serialMonitor.baudWarningTitle',
        defaultMessage: 'Caution',
        description: 'Warning title when specific baud rate is selected'
    },
    baudWarningMessage: {
        id: 'gui.serialMonitor.baudWarningMessage',
        defaultMessage: 'The connection will be reset automatically over time under this mode. Please use it only under instructions.',
        description: 'Warning message when specific baud rate is selected'
    },
    clearButtonTitle: {
        id: 'gui.serialMonitor.clearButtonTitle',
        defaultMessage: 'Clear',
        description: 'Clear button title'
    },
});
