const LOCK_SERIAL = 'scratch-gui/web-serial/LOCK_SERIAL';

const UNLOCK_INDEX = 0;
const FLASH_LOCKED_INDEX = 1;
const MONITOR_LOCKED_INDEX = 2;

/**
 * The initial state for web serial control.
 * locked: UNLOCK_INDEX, FLASH_LOCKED_INDEX, MONITOR_LOCKED_INDEX
 * - UNLOCK_INDEX: unlocked
 * - FLASH_LOCKED_INDEX: locked for flashing
 * - MONITOR_LOCKED_INDEX: locked for monitoring
 * ready: true, false (whether ready to next web serial action even if locked)
 * - true: ready to next web serial action
 * - false: not ready to next web serial action
 */
const initialState = {
    locked: UNLOCK_INDEX,
    ready: true,
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case LOCK_SERIAL:
        return Object.assign({}, state, {
            locked: action.locked,
            ready: action.ready
        });
    default:
        return state;
    }
};

const lockSerial = function (locked, ready) {
    return {
        type: LOCK_SERIAL,
        locked: locked,
        ready: ready
    };
};

export {
    reducer as default,
    initialState as webSerialInitialState,
    lockSerial,
    UNLOCK_INDEX,
    FLASH_LOCKED_INDEX,
    MONITOR_LOCKED_INDEX,
};