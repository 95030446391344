const ACTIVATE_TAB = 'scratch-gui/navigation/ACTIVATE_TAB';
const SET_EDITOR = 'scratch-gui/editor-tab/SET_EDITOR';

// Constants use numbers to make it easier to work with react-tabs
const BLOCKS_TAB_INDEX = 0;
const COSTUMES_TAB_INDEX = 1;
const SOUNDS_TAB_INDEX = 2;

// Editor tab index for board mode
const EDITOR_TAB_INDEX = 1;

const initialState = {
    activeTabIndex: BLOCKS_TAB_INDEX,
    // set editor of the editor tab
    editor: null,
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case ACTIVATE_TAB:
        return Object.assign({}, state, {
            activeTabIndex: action.activeTabIndex
        });
    case SET_EDITOR:
        return Object.assign({}, state, {
            editor: action.editor
        });
    default:
        return state;
    }
};

const activateTab = function (tab) {
    return {
        type: ACTIVATE_TAB,
        activeTabIndex: tab
    };
};

const setEditor = function (editor) {
    return {
        type: SET_EDITOR,
        editor: editor
    };
};

export {
    reducer as default,
    initialState as editorTabInitialState,
    activateTab,
    setEditor,
    BLOCKS_TAB_INDEX,
    COSTUMES_TAB_INDEX,
    SOUNDS_TAB_INDEX,
    EDITOR_TAB_INDEX
};
