import React from 'react';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import VM from 'scratch-vm';

import {injectIntl} from 'react-intl';
import {connect} from 'react-redux';

import ConsoleComponent from '../components/console/console.jsx';
import {BLOCKS_TAB_INDEX} from '../reducers/editor-tab';

import {CodeMirror} from 'code-editor';

class Console extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleToggle',
            'handleClearConsole',
            'handleUpdateConsole',
        ]);

        this.state = {
            expanded: this.props.expanded,
        }

        this.canvas = document.createElement('div');
        this.console_field = new CodeMirror(this.canvas, {
            lineNumbers: false,
            autoScrollToEnd: true,
        });
    }

    componentDidMount () {
        this.props.vm.addListener('CLEAR_CONSOLE', this.handleClearConsole);
        this.props.vm.addListener('UPDATE_CONSOLE', this.handleUpdateConsole);
    }

    componentWillUnmount () {
        this.props.vm.removeListener('CLEAR_CONSOLE', this.handleClearConsole);
        this.props.vm.removeListener('UPDATE_CONSOLE', this.handleUpdateConsole);
    }


    componentDidUpdate() {
        window.dispatchEvent(new Event('resize'));
    }

    handleToggle () {
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    handleClearConsole() {
        this.console_field.update('');
    }

    handleUpdateConsole(data) {
        this.console_field.update(data);
    }

    getTabExpanded() {
        return this.state.expanded;
    }

    render () {
        return (
            <ConsoleComponent
                canvas={this.canvas}
                disabled={this.props.scratchMode}
                expanded={this.state.expanded}
                onToggle={this.props.scratchMode ? null : this.handleToggle}
            />
        )
    }
}

Console.propTypes = {
    scratchMode: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
    expanded: PropTypes.bool,
}

const mapStateToProps = state => ({
    scratchMode: state.scratchGui.vmStatus.scratch_mode,
    expanded: false,
});

export default injectIntl(connect(
    mapStateToProps
)(Console));