import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import VM from 'scratch-vm';

import BoardModalComponent from '../components/board-modal/board-modal.jsx';

import {connect} from 'react-redux';

import {setCompilationState, BOARD_ARDUINO_UNO, BOARD_ARDUINO_NANO} from '../reducers/boards';
import {closeBoardModal} from '../reducers/modals';

import { enqueueSnackbar, closeSnackbar } from 'notistack';

class BoardModal extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'showSnackBar',
        ]);

        this.snackbarId = null;
    }

    showSnackBar (message) {
        if (this.snackbarId) closeSnackbar(this.snackbarId);
        this.snackbarId = enqueueSnackbar(message, {
            variant: 'success',
            autoHideDuration: 1500,
        });
    }

    render () {        
        return (
            <BoardModalComponent 
                onCancel={this.props.onCancel}
                showSnackBar={this.showSnackBar}
                {...this.props.onUpdate}
            />
        );
    }
}

BoardModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onUpdate: PropTypes.objectOf(PropTypes.func).isRequired,
    vm: PropTypes.instanceOf(VM).isRequired,
};

const mapDispatchToProps = dispatch => {
    const onCancel = () => {
        dispatch(closeBoardModal());
    };
    return {
        onCancel: onCancel,
        onUpdate: {
            onSetArduinoUNO: () => {
                dispatch(setCompilationState(BOARD_ARDUINO_UNO));
                onCancel();
            },
            onSetArduinoNANO: () => {
                dispatch(setCompilationState(BOARD_ARDUINO_NANO));
                onCancel();
            }
        }
    }
};

export default connect(
    null,
    mapDispatchToProps
)(BoardModal);