import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import VM from 'scratch-vm';
import {connect} from 'react-redux';

import ControlsComponent from '../components/controls/controls.jsx';

import "regenerator-runtime/runtime";
import {getHexMap, flashBoard} from '../lib/flash-utils';

import {
    BLOCKS_TAB_INDEX,
    EDITOR_TAB_INDEX
} from '../reducers/editor-tab';

import { enqueueSnackbar, closeSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    lockSerial,
    UNLOCK_INDEX,
    FLASH_LOCKED_INDEX,
    MONITOR_LOCKED_INDEX,
} from '../reducers/web-serial.js';

class Controls extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleGreenFlagClick',
            'handleUploadClick',
            'handleStopAllClick',
            'handleVerifyClick',
        ]);

        this.snackbar = {
            loading: (text) => {
                return enqueueSnackbar(text, {
                    variant: 'info',
                    iconVariant: {
                        info: <div
                            style={{
                                display: 'flex',
                                marginRight: '10px',
                            }}
                        >
                            <CircularProgress 
                                size={20}
                            />
                        </div>,
                    },
                    autoHideDuration: null,
                });
            },
            success: (text) => {
                enqueueSnackbar(text, {
                    variant: 'success',
                    autoHideDuration: 2000,
                });
            },
            fail: (text) => {
                enqueueSnackbar(text, {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
            }
        }

        this.state = {
            verifying: false,
        }
    }
    handleGreenFlagClick (e) {
        e.preventDefault();
        if (e.shiftKey) {
            this.props.vm.setTurboMode(!this.props.turbo);
        } else {
            if (!this.props.isStarted) {
                this.props.vm.start();
            }
            this.props.vm.greenFlag();
        }
    }
    async handleUploadClick (e, cb) {
        if (e) {
            e.preventDefault();
        }
        if (this.props.webSerialLockState === UNLOCK_INDEX) {
            this.props.lockWebSerial(FLASH_LOCKED_INDEX, true);
            const compileSnackbarID = this.snackbar.loading('Compiling...');
            this.props.vm.emit('CLEAR_CONSOLE');

            const hex = await this.getSourceCode((status, err)=>{
                closeSnackbar(compileSnackbarID);
                // todo: check whether the error is caused by compilation error / other error (e.g. network error)
                if (!status) {
                    this.props.lockWebSerial(UNLOCK_INDEX, true);
                    this.snackbar.fail('Compilation Error');
                    this.props.vm.emit('UPDATE_CONSOLE', err.message);
                }
            });

            const flashSnackbarID = this.snackbar.loading('Flashing...');
            const failback = (err) => {
                this.props.lockWebSerial(UNLOCK_INDEX, true);
                closeSnackbar(flashSnackbarID);
                this.snackbar.fail('Flash Failed');
                
                if (err && err instanceof Error) {
                    this.props.vm.emit('UPDATE_CONSOLE', err.message);
                }
            }
            try {
                flashBoard(this.props.board.name, hex, (state, err) => {
                    this.props.lockWebSerial(UNLOCK_INDEX, true);
                    closeSnackbar(flashSnackbarID);
                    if (state) {
                        this.snackbar.success('Flash Successful!');
                    } else {
                        failback(err);
                    }

                    if (cb) {
                        cb();
                    }
                });
            } catch (err) {
                failback(err);
                throw err;
            }
        } else if (this.props.webSerialLockState === MONITOR_LOCKED_INDEX && this.props.webSerialReady) {
            this.props.vm.emit('FLASH_WITH_MONITOR', this.handleUploadClick);
        }
    }
    handleStopAllClick (e) {
        e.preventDefault();
        this.props.vm.stopAll();
    }
    async handleVerifyClick (e) {
        e.preventDefault();
        if (this.state.verifying) {
            return;
        }

        const snackbarID = this.snackbar.loading('Verifying...');
        this.props.vm.emit('CLEAR_CONSOLE');
        this.setState({verifying: true});

        this.getSourceCode((status, err)=>{
            closeSnackbar(snackbarID);
            if (status) {
                this.snackbar.success('Verify Successful!');
            } else {
                this.snackbar.fail('Verify Failed');
                this.props.vm.emit('UPDATE_CONSOLE', err.message);
            }
            this.setState({verifying: false});
        });
    }
    async getSourceCode (callback) {
        var code = '';
        const isEditorTab = this.props.activeTab === EDITOR_TAB_INDEX;

        if (isEditorTab && this.props.codeEditor !== null) {
            code = this.props.codeEditor.getText();
        } else if (this.props.activeTab === BLOCKS_TAB_INDEX) {
            code = this.props.vm.getGeneratorCode();
        } else {
            throw new Error("Unexpected error: no code editor found in editor tab");
        }

        const {status, message, hex} = await getHexMap(this.props.board.fqbn, code);
        if (status) {
            if (isEditorTab) {
                this.props.codeEditor.highlightLine(-1);
            }

            if (callback) {
                callback(true);
            }
            return hex;
        } else {
            const err = new Error(message)
            if (isEditorTab) {
                const errors = err.message.match(/:\d+:\d+: error:/g);
                if (errors.length > 0) {
                    const errLine = errors[0].split(":")[1];
                    this.props.codeEditor.highlightLine(errLine);
                }
            }

            if (callback) {
                callback(false, err);
            }
            throw err;
        }
    }
    render () {
        const {
            vm, // eslint-disable-line no-unused-vars
            isStarted, // eslint-disable-line no-unused-vars
            projectRunning,
            turbo,
            activeTab,
            codeEditor,
            webSerialLockState,
            webSerialReady,
            lockWebSerial,
            projectTitle,
            scratchMode,
            ...props
        } = this.props;
        return (
            <ControlsComponent
                {...props}
                // Board mode
                disableFlash={this.props.webSerialLockState === MONITOR_LOCKED_INDEX && !this.props.webSerialReady}
                flashing={this.props.webSerialLockState === FLASH_LOCKED_INDEX}
                verifying={this.state.verifying}
                onUploadClick={this.handleUploadClick}
                onVerifyClick={this.handleVerifyClick}
                // Scratch mode
                active={projectRunning}
                turbo={turbo}
                onGreenFlagClick={this.handleGreenFlagClick}
                onStopAllClick={this.handleStopAllClick}
            />
        );
    }
}

Controls.propTypes = {
    isStarted: PropTypes.bool.isRequired,
    projectRunning: PropTypes.bool.isRequired,
    turbo: PropTypes.bool.isRequired,
    vm: PropTypes.instanceOf(VM),
    activeTab: PropTypes.number.isRequired,
    codeEditor: PropTypes.object,
    webSerialLockState: PropTypes.number.isRequired,
    webSerialReady: PropTypes.bool.isRequired,
    projectTitle: PropTypes.string,
    scratchMode: PropTypes.bool,
    board: PropTypes.object,
};

const mapStateToProps = state => ({
    isStarted: state.scratchGui.vmStatus.running,
    projectRunning: state.scratchGui.vmStatus.running,
    turbo: state.scratchGui.vmStatus.turbo,
    activeTab: state.scratchGui.editorTab.activeTabIndex,
    codeEditor: state.scratchGui.editorTab.editor,
    webSerialLockState: state.scratchGui.webSerial.locked,
    webSerialReady: state.scratchGui.webSerial.ready,
    projectTitle: state.scratchGui.projectTitle,
    scratchMode: state.scratchGui.vmStatus.scratch_mode,
    board: state.scratchGui.board,
});

const mapDispatchToProps = dispatch => ({
    lockWebSerial: (lock_index, ready) => dispatch(lockSerial(lock_index, ready)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
