const getSerialPort = async(callback, vendorIDs=[], usePair=true) => {
    vendorIDs = vendorIDs.map(function(entry) {
        return Number(entry);
    });

    const vendorIDFilter = [];
    vendorIDs.forEach((id) => {
        vendorIDFilter.push({ usbVendorId: id });
    });

    try {
        // search for a paired device
        if (usePair) { 
            const ports = await navigator.serial.getPorts();
            for (const p of ports) {
                const info = await p.getInfo();
                if (vendorIDs.includes(info.usbVendorId)) {
                    return p;
                }
            }
        }

        return await navigator.serial.requestPort({
            filters: vendorIDFilter,
        });
    } catch (e) {
        callback(false);
        throw(e);
    }
}


export {
    getSerialPort,
};
