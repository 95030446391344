import React from 'react';
import bindAll from 'lodash.bindall';

import SnackBarComponent from '../components/snack-bar/snack-bar.jsx';

class SnackBar extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
        ]);
    }
    
    render () {
        const {
        } = this.props;
        return (
            <SnackBarComponent />
        );
    }
}

export default SnackBar;
