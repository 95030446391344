import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import verifyButtonIcon from './icon--verify-button.svg';
import styles from './verify-button.css';

const VerifyButtonComponent = function (props) {
    const {
        active,
        className,
        onClick,
        title,
        ...componentProps
    } = props;

    return (
        <div className={styles.container}>

            {active ? <CircularProgress size={28} className={classNames(styles.fabProgress)} /> : null}
            <img
                className={classNames(
                    className,
                    styles.verifyButton,
                    {
                        [styles.isActive]: active
                    }
                )}
                draggable={false}
                src={verifyButtonIcon}
                title={title}
                onClick={onClick}
                {...componentProps}
            />
        </div>
    );
};

VerifyButtonComponent.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string
};

VerifyButtonComponent.defaultProps = {
    active: false,
    title: 'Verify'
};

export default VerifyButtonComponent;