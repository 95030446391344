import {defineMessages} from 'react-intl';

export default defineMessages({
    title: {
        id: 'gui.exampleModal.title',
        defaultMessage: 'Choose an Example',
        description: 'Title for the example selection modal'
    },
});

