import classNames from 'classnames';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {defineMessages, FormattedMessage, injectIntl, intlShape} from 'react-intl';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import bowser from 'bowser';
import React from 'react';

import VM from 'scratch-vm';
import { enqueueSnackbar, closeSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table, Tag, Popover } from 'antd';

import Box from '../box/box.jsx';
import Button from '../button/button.jsx';
import CommunityButton from './community-button.jsx';
import ShareButton from './share-button.jsx';
import {ComingSoonTooltip} from '../coming-soon/coming-soon.jsx';
import Divider from '../divider/divider.jsx';
import SaveStatus from './save-status.jsx';
import ProjectWatcher from '../../containers/project-watcher.jsx';
import MenuBarMenu from './menu-bar-menu.jsx';
import {MenuItem, MenuSection} from '../menu/menu.jsx';
import ProjectTitleInput from './project-title-input.jsx';
import AuthorInfo from './author-info.jsx';
import AccountNav from '../../containers/account-nav.jsx';
import LoginDropdown from './login-dropdown.jsx';
import SB3Downloader from '../../containers/sb3-downloader.jsx';
import DeletionRestorer from '../../containers/deletion-restorer.jsx';
import TurboMode from '../../containers/turbo-mode.jsx';
import MenuBarHOC from '../../containers/menu-bar-hoc.jsx';
import SettingsMenu from './settings-menu.jsx';
import ToggleButtons from '../toggle-buttons/toggle-buttons.jsx';
import ScratchMode from '../../containers/scratch-mode.jsx';
import LanguageMenu from '../menu-bar--language/language-menu.jsx';
import AlertDialog from '../dialog/dialog.jsx';

import {openBoardModal, openTipsLibrary, openExampleLibrary} from '../../reducers/modals';
import {setPlayer} from '../../reducers/mode';
import {
    isTimeTravel220022BC,
    isTimeTravel1920,
    isTimeTravel1990,
    isTimeTravel2020,
    isTimeTravelNow,
    setTimeTravel
} from '../../reducers/time-travel';
import {
    autoUpdateProject,
    getIsUpdating,
    getIsShowingProject,
    manualUpdateProject,
    setProjectId,
    createProject,
    requestNewProject,
    remixProject,
    saveProjectAsCopy
} from '../../reducers/project-state';
import {
    openAboutMenu,
    closeAboutMenu,
    aboutMenuOpen,
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen,
    openModeMenu,
    closeModeMenu,
    modeMenuOpen,
    settingsMenuOpen,
    openSettingsMenu,
    closeSettingsMenu,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
} from '../../reducers/menus';

import collectMetadata from '../../lib/collect-metadata';
import { getSerialPort } from '../../lib/serial-utils.js';

import styles from './menu-bar.css';

import helpIcon from '../../lib/assets/icon--tutorials.svg';
import mystuffIcon from './icon--mystuff.png';
import profileIcon from './icon--blueinno-profile.png';
import remixIcon from './icon--remix.svg';
import dropdownCaret from './dropdown-caret.svg';
import aboutIcon from './icon--about.svg';
import fileIcon from './icon--file.svg';
import editIcon from './icon--edit.svg';
import saveIcon from './icon--save.svg';
import blueinnoLogo from './icon--blueinno-logo.svg';
import ninetiesLogo from './nineties_logo.svg';
import catLogo from './cat_logo.svg';
import prehistoricLogo from './prehistoric-logo.svg';
import oldtimeyLogo from './oldtimey-logo.svg';
import pairIcon from './icon--pair.svg';
import boardIcon from './icon--board.svg';

import sharedMessages from '../../lib/shared-messages';
import menuBarMessages from './menu-bar-messages.js';

import {
    activateTab,
    BLOCKS_TAB_INDEX,
    EDITOR_TAB_INDEX
} from '../../reducers/editor-tab';
import downloadBlob from '../../lib/download-blob';
import { serial } from 'web-serial-polyfill';

const ariaMessages = defineMessages({
    tutorials: {
        id: 'gui.menuBar.tutorialsLibrary',
        defaultMessage: 'Tutorials',
        description: 'accessibility text for the tutorials button'
    }
});

const MenuBarItemTooltip = ({
    children,
    className,
    enable,
    id,
    place = 'bottom'
}) => {
    if (enable) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        );
    }
    return (
        <ComingSoonTooltip
            className={classNames(styles.comingSoon, className)}
            place={place}
            tooltipClassName={styles.comingSoonTooltip}
            tooltipId={id}
        >
            {children}
        </ComingSoonTooltip>
    );
};


MenuBarItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    enable: PropTypes.bool,
    id: PropTypes.string,
    place: PropTypes.oneOf(['top', 'bottom', 'left', 'right'])
};

const MenuItemTooltip = ({id, isRtl, children, className}) => (
    <ComingSoonTooltip
        className={classNames(styles.comingSoon, className)}
        isRtl={isRtl}
        place={isRtl ? 'left' : 'right'}
        tooltipClassName={styles.comingSoonTooltip}
        tooltipId={id}
    >
        {children}
    </ComingSoonTooltip>
);

MenuItemTooltip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isRtl: PropTypes.bool
};

const AboutButton = props => (
    <Button
        className={classNames(styles.menuBarItem, styles.hoverable)}
        iconClassName={styles.aboutIcon}
        iconSrc={aboutIcon}
        onClick={props.onClick}
    />
);

AboutButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

class MenuBar extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleClickNew',
            'handleClickConvert',
            'handleClickRemix',
            'handleClickSave',
            'handleClickSaveAsCopy',
            'handleClickSeeCommunity',
            'handleClickShare',
            'handleClickUndo',
            'handleClickFormat',
            'handleConvert',
            'handleSetMode',
            'handleKeyPress',
            'handleRestoreOption',
            'saveInoToComputerHandler',
            'getSaveToComputerHandler',
            'handleSelectingFileUpload',
            'restoreOptionMessage',
        ]);

        this.state = {
            isOpenConvertDialog: false,
        }

        this.snackbarIds = {
            download: null,
            upload: null,
            serial: null,
        }
    }
    componentDidMount () {
        document.addEventListener('keydown', this.handleKeyPress);
    }
    componentWillUnmount () {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
    handleClickNew () {
        // if the project is dirty, and user owns the project, we will autosave.
        // but if they are not logged in and can't save, user should consider
        // downloading or logging in first.
        // Note that if user is logged in and editing someone else's project,
        // they'll lose their work.
        // Also, we add a special case for editor board mode, since we don't want to clear the blocks.
        if (!this.props.scratchMode && this.props.activeTabIndex === EDITOR_TAB_INDEX) {
            // clear the code editor & console
            // todo: may add a confirmation dialog here
            this.props.vm.emit('RESET_CODE_EDITOR');
            this.props.vm.emit('CLEAR_CONSOLE');
            this.props.onRequestCloseFile();
        } else {
            // create new project & replace current vm project if user confirms
            const readyToReplaceProject = this.props.confirmReadyToReplaceProject(
                this.props.intl.formatMessage(sharedMessages.replaceProjectWarning)
            );
            this.props.onRequestCloseFile();
            if (readyToReplaceProject) {
                this.props.onClickNew(this.props.canSave && this.props.canCreateNew);
            }
            this.props.onRequestCloseFile();
        }
    }
    handleClickConvert(status) {
        this.setState({isOpenConvertDialog: status});
    }
    handleConvert(e) {
        e.preventDefault();

        if (this.props.scratchMode || this.props.activeTabIndex !== BLOCKS_TAB_INDEX) {
            return;
        }

        var code = this.props.vm.getGeneratorCode();
        // wait for the editor tab to be loaded before updating the code
        Promise.resolve(this.props.onActivateEditorTab()).then(() => this.props.vm.emit('UPDATE_CODE_EDITOR', code));
    }
    handleClickRemix () {
        this.props.onClickRemix();
        this.props.onRequestCloseFile();
    }
    handleClickSave () {
        this.props.onClickSave();
        this.props.onRequestCloseFile();
    }
    handleClickSaveAsCopy () {
        this.props.onClickSaveAsCopy();
        this.props.onRequestCloseFile();
    }
    handleClickSeeCommunity (waitForUpdate) {
        if (this.props.shouldSaveBeforeTransition()) {
            this.props.autoUpdateProject(); // save before transitioning to project page
            waitForUpdate(true); // queue the transition to project page
        } else {
            waitForUpdate(false); // immediately transition to project page
        }
    }
    handleClickShare (waitForUpdate) {
        if (!this.props.isShared) {
            if (this.props.canShare) { // save before transitioning to project page
                this.props.onShare();
            }
            if (this.props.canSave) { // save before transitioning to project page
                this.props.autoUpdateProject();
                waitForUpdate(true); // queue the transition to project page
            } else {
                waitForUpdate(false); // immediately transition to project page
            }
        }
    }
    handleClickUndo(redo) {
        if (!this.props.scratchMode && this.props.activeTabIndex === EDITOR_TAB_INDEX) {
            this.props.vm.emit("UNDO_CODE_EDITOR", redo);
        } else {
            this.props.vm.emit("WORKSPACE_UNDO", redo);
        }
    }
    handleClickFormat() {
        this.props.vm.emit("FORMAT_CODE_EDITOR");
    }
    handleSetMode (mode) {
        return () => {
            // Turn on/off filters for modes.
            if (mode === '1920') {
                document.documentElement.style.filter = 'brightness(.9)contrast(.8)sepia(1.0)';
                document.documentElement.style.height = '100%';
            } else if (mode === '1990') {
                document.documentElement.style.filter = 'hue-rotate(40deg)';
                document.documentElement.style.height = '100%';
            } else {
                document.documentElement.style.filter = '';
                document.documentElement.style.height = '';
            }

            // Change logo for modes
            if (mode === '1990') {
                document.getElementById('logo_img').src = ninetiesLogo;
            } else if (mode === '2020') {
                document.getElementById('logo_img').src = catLogo;
            } else if (mode === '1920') {
                document.getElementById('logo_img').src = oldtimeyLogo;
            } else if (mode === '220022BC') {
                document.getElementById('logo_img').src = prehistoricLogo;
            } else {
                document.getElementById('logo_img').src = this.props.logo;
            }

            this.props.onSetTimeTravelMode(mode);
        };
    }
    handleRestoreOption (restoreFun) {
        return () => {
            restoreFun();
            this.props.onRequestCloseEdit();
        };
    }
    handleKeyPress (event) {
        const modifier = bowser.mac ? event.metaKey : event.ctrlKey;
        if (modifier && event.key === 's') {
            this.props.onClickSave();
            event.preventDefault();
        }
    }
    saveInoToComputerHandler () {
        this.props.onRequestCloseFile();
        var code = this.props.codeEditor.getText();
        // replace all spaces with dashes as Arduino IDE cannot open files with spaces in the name
        var filename = this.props.projectTitle.replace(/ /g, '-') + '.ino';

        const blob = new Blob([code], {type: 'text/plain'});
        downloadBlob(filename, blob);
        if (this.snackbarIds.download) closeSnackbar(this.snackbarIds.download);
        this.snackbarIds.download = enqueueSnackbar(this.props.intl.formatMessage(menuBarMessages.downloadIsReady), {
            variant: 'success',
            autoHideDuration: 2000,
        });
    }
    getSaveToComputerHandler (downloadProjectCallback) {
        return () => {
            this.props.onRequestCloseFile();
            downloadProjectCallback();
            if (this.props.onProjectTelemetryEvent) {
                const metadata = collectMetadata(this.props.vm, this.props.projectTitle, this.props.locale);
                this.props.onProjectTelemetryEvent('projectDidSave', metadata);
            }
            this.snackbarIds.download = enqueueSnackbar(this.props.intl.formatMessage(menuBarMessages.downloadIsReady), {
                variant: 'success',
                autoHideDuration: 2000,
            });
        };
    }
    handleSelectingFileUpload() {
        this.props.onStartSelectingFileUpload(!this.props.scratchMode && this.props.activeTabIndex === EDITOR_TAB_INDEX);
        if (this.snackbarIds.upload) closeSnackbar(this.snackbarIds.upload);
        this.snackbarIds.upload = enqueueSnackbar(this.props.intl.formatMessage(menuBarMessages.uploading), {
            variant: 'info',
            autoHideDuration: 1000,
        });
    }
    restoreOptionMessage (deletedItem) {
        switch (deletedItem) {
        case 'Sprite':
            return (<FormattedMessage
                defaultMessage="Restore Sprite"
                description="Menu bar item for restoring the last deleted sprite."
                id="gui.menuBar.restoreSprite"
            />);
        case 'Sound':
            return (<FormattedMessage
                defaultMessage="Restore Sound"
                description="Menu bar item for restoring the last deleted sound."
                id="gui.menuBar.restoreSound"
            />);
        case 'Costume':
            return (<FormattedMessage
                defaultMessage="Restore Costume"
                description="Menu bar item for restoring the last deleted costume."
                id="gui.menuBar.restoreCostume"
            />);
        default: {
            return (<FormattedMessage
                defaultMessage="Restore"
                description="Menu bar item for restoring the last deleted item in its disabled state." /* eslint-disable-line max-len */
                id="gui.menuBar.restore"
            />);
        }
        }
    }
    buildAboutMenu (onClickAbout) {
        if (!onClickAbout) {
            // hide the button
            return null;
        }
        if (typeof onClickAbout === 'function') {
            // make a button which calls a function
            return <AboutButton onClick={onClickAbout} />;
        }
        // assume it's an array of objects
        // each item must have a 'title' FormattedMessage and a 'handleClick' function
        // generate a menu with items for each object in the array
        return (
            <div
                className={classNames(styles.menuBarItem, styles.hoverable, {
                    [styles.active]: this.props.aboutMenuOpen
                })}
                onMouseUp={this.props.onRequestOpenAbout}
            >
                <img
                    className={styles.aboutIcon}
                    src={aboutIcon}
                />
                <MenuBarMenu
                    className={classNames(styles.menuBarMenu)}
                    open={this.props.aboutMenuOpen}
                    place={this.props.isRtl ? 'right' : 'left'}
                    onRequestClose={this.props.onRequestCloseAbout}
                >
                    {
                        onClickAbout.map(itemProps => (
                            <MenuItem
                                key={itemProps.title}
                                isRtl={this.props.isRtl}
                                onClick={this.wrapAboutMenuCallback(itemProps.onClick)}
                            >
                                {itemProps.title}
                            </MenuItem>
                        ))
                    }
                </MenuBarMenu>
            </div>
        );
    }
    wrapAboutMenuCallback (callback) {
        return () => {
            callback();
            this.props.onRequestCloseAbout();
        };
    }
    render () {

        const remixButton = (
            <Button
                className={classNames(
                    styles.menuBarButton,
                    styles.remixButton
                )}
                iconClassName={styles.remixButtonIcon}
                iconSrc={remixIcon}
                onClick={this.handleClickRemix}
            >
                {this.props.intl.formatMessage(menuBarMessages.remix)}
            </Button>
        );
        // Show the About button only if we have a handler for it (like in the desktop app)
        const aboutButton = this.buildAboutMenu(this.props.onClickAbout);

        return (
            <Box
                className={classNames(
                    this.props.className,
                    styles.menuBar
                )}
            >
                <div className={styles.mainMenu}>
                    <div className={styles.fileGroup}>
                        <div className={classNames(styles.menuBarItem)}>
                            <Popover content={(
                                <Table dataSource={[
                                    {
                                        key: '1',
                                        source: 'GUI',
                                        version: <Tag color="cyan">{process.env.VERSION?.GUI}</Tag>
                                    },
                                    {
                                        key: '2',
                                        source: 'VM',
                                        version: <Tag color="blue">{process.env.VERSION?.VM}</Tag>
                                    },
                                    {
                                        key: '3',
                                        source: 'BLOCKS',
                                        version: <Tag color="geekblue">{process.env.VERSION?.BLOCKS}</Tag>
                                    },
                                ]} columns={[
                                    {
                                        title: '',
                                        dataIndex: 'source',
                                        key: 'source',
                                    },
                                    {
                                        title: '',
                                        dataIndex: 'version',
                                        key: 'version',
                                    },
                                ]} pagination={false} showHeader={false} bordered={true} size="small" />
                            )} arrow={{pointAtCenter: true}} placement="bottomLeft" title="Version">
                                <img
                                    id="logo_img"
                                    alt="Blueinno Icon"
                                    className={classNames(styles.blueinnoLogo, {
                                        [styles.clickable]: typeof this.props.onClickLogo !== 'undefined'
                                    })}
                                    draggable={false}
                                    src={this.props.logo}
                                    style={{'cursor': 'default'}}
                                />
                            </Popover>
                        </div>
                        {(this.props.canManageFiles) && (
                            <div
                                className={classNames(styles.menuBarItem, styles.hoverable, {
                                    [styles.active]: this.props.fileMenuOpen
                                })}
                                onMouseUp={this.props.onClickFile}
                            >
                                <img src={fileIcon} style={{height: '17px'}} draggable={false}/>
                                <span className={styles.collapsibleLabel}>
                                    {this.props.intl.formatMessage(menuBarMessages.file)}
                                </span>
                                <img src={dropdownCaret} draggable={false}/>
                                <MenuBarMenu
                                    className={classNames(styles.menuBarMenu)}
                                    open={this.props.fileMenuOpen}
                                    place={this.props.isRtl ? 'left' : 'right'}
                                    onRequestClose={this.props.onRequestCloseFile}
                                >
                                    <MenuSection>
                                        <MenuItem isRtl={this.props.isRtl} onClick={this.handleClickNew} >
                                            {this.props.intl.formatMessage(menuBarMessages.newProject)}
                                        </MenuItem>
                                        { this.props.isShowingExamples && (
                                            <MenuItem isRtl={this.props.isRtl} onClick={this.props.onOpenExampleLibrary}>
                                                {this.props.intl.formatMessage(menuBarMessages.examples)}
                                            </MenuItem>
                                        )}
                                    </MenuSection>
                                    {/* hide download / save menu btn */}
                                    {(this.props.canSave || this.props.canCreateCopy || this.props.canRemix) && (
                                        <MenuSection>
                                            {this.props.canSave && (
                                                <MenuItem onClick={this.handleClickSave}>
                                                    {this.props.intl.formatMessage(menuBarMessages.saveNow)}
                                                </MenuItem>
                                            )}
                                            {this.props.canCreateCopy && (
                                                <MenuItem onClick={this.handleClickSaveAsCopy}>
                                                    {this.props.intl.formatMessage(menuBarMessages.createCopy)}
                                                </MenuItem>
                                            )}
                                            {this.props.canRemix && (
                                                <MenuItem onClick={this.handleClickRemix}>
                                                    {this.props.intl.formatMessage(menuBarMessages.remix)}
                                                </MenuItem>
                                            )}
                                        </MenuSection>
                                    )} 
                                    <MenuSection>
                                    {(!this.props.scratchMode && this.props.activeTabIndex === EDITOR_TAB_INDEX) ? (
                                        <MenuItem onClick={this.handleSelectingFileUpload}>
                                            {this.props.intl.formatMessage(sharedMessages.loadCodesFromComputerTitle)}
                                        </MenuItem>
                                    ) : (
                                        <MenuItem onClick={this.handleSelectingFileUpload}>
                                            {this.props.intl.formatMessage(sharedMessages.loadBlocksFromComputerTitle)}
                                        </MenuItem>
                                    )}
                                    {(!this.props.scratchMode && this.props.activeTabIndex === EDITOR_TAB_INDEX) ? (
                                        <MenuItem onClick={this.saveInoToComputerHandler}>
                                            {this.props.intl.formatMessage(sharedMessages.downloadCodesToComputerTitle)}
                                        </MenuItem>
                                    ) : (
                                        <SB3Downloader>{(className, downloadProjectCallback) => (
                                            <MenuItem
                                                className={className}
                                                onClick={this.getSaveToComputerHandler(downloadProjectCallback)}
                                            >
                                                {this.props.intl.formatMessage(sharedMessages.downloadBlocksToComputerTitle)}
                                            </MenuItem>
                                        )}</SB3Downloader>
                                    )}
                                    </MenuSection>
                                    {(!this.props.scratchMode && this.props.activeTabIndex === BLOCKS_TAB_INDEX) && (
                                        <MenuSection>
                                            <MenuItem
                                                isRtl={this.props.isRtl}
                                                onClick={() => this.handleClickConvert(true)}
                                            >
                                                {this.props.intl.formatMessage(menuBarMessages.convertProject)}
                                            </MenuItem>
                                        </MenuSection>
                                    )}
                                </MenuBarMenu>
                            </div>
                        )}
                        {(this.props.showEditMenu) && (
                            <div
                                className={classNames(styles.menuBarItem, styles.hoverable, {
                                    [styles.active]: this.props.editMenuOpen
                                })}
                                onMouseUp={this.props.onClickEdit}
                            >
                                <img src={editIcon} draggable={false}/>
                                <span className={styles.collapsibleLabel}>
                                    {this.props.intl.formatMessage(menuBarMessages.edit)}
                                </span>
                                <img src={dropdownCaret} draggable={false}/>
                                <MenuBarMenu
                                    className={classNames(styles.menuBarMenu)}
                                    open={this.props.editMenuOpen}
                                    place={this.props.isRtl ? 'left' : 'right'}
                                    onRequestClose={this.props.onRequestCloseEdit}
                                >
                                    {(this.props.scratchMode) && (
                                        <DeletionRestorer>{(handleRestore, {restorable, deletedItem}) => (
                                            <MenuItem
                                                className={classNames({[styles.disabled]: !restorable})}
                                                onClick={this.handleRestoreOption(handleRestore)}
                                            >
                                                {this.restoreOptionMessage(deletedItem)}
                                            </MenuItem>
                                        )}</DeletionRestorer>
                                    )}

                                    {(this.props.scratchMode) && (
                                        <MenuSection>
                                            <TurboMode>{(toggleTurboMode, {turboMode}) => (
                                                <MenuItem onClick={toggleTurboMode}>
                                                    {turboMode ? (
                                                        this.props.intl.formatMessage(menuBarMessages.turnOffTurboMode)
                                                    ) : (
                                                        this.props.intl.formatMessage(menuBarMessages.turnOnTurboMode)
                                                    )}
                                                </MenuItem>
                                            )}</TurboMode>
                                        </MenuSection>
                                    )}

                                    {(!this.props.scratchMode) && (
                                        <MenuSection>
                                            <MenuItem
                                                isRtl={this.props.isRtl}
                                                onClick={() => this.handleClickUndo(false)}
                                            >
                                                {this.props.intl.formatMessage(menuBarMessages.undo)}
                                            </MenuItem>
                                            <MenuItem
                                                isRtl={this.props.isRtl}
                                                onClick={() => this.handleClickUndo(true)}
                                            >
                                                {this.props.intl.formatMessage(menuBarMessages.redo)}
                                            </MenuItem>
                                        </MenuSection>
                                    )}

                                    {(!this.props.scratchMode && this.props.activeTabIndex === EDITOR_TAB_INDEX) && (
                                        <MenuSection>
                                            <MenuItem
                                                isRtl={this.props.isRtl}
                                                onClick={this.handleClickFormat}
                                            >
                                                {this.props.intl.formatMessage(menuBarMessages.format)}
                                            </MenuItem>
                                        </MenuSection>
                                    )}
                                </MenuBarMenu>

                            </div>
                        )}
                        {this.props.isTotallyNormal && (
                            <div
                                className={classNames(styles.menuBarItem, styles.hoverable, {
                                    [styles.active]: this.props.modeMenuOpen
                                })}
                                onMouseUp={this.props.onClickMode}
                            >
                                <div className={classNames(styles.editMenu)}>
                                    {this.props.intl.formatMessage(menuBarMessages.modeMenu)}
                                </div>
                                <MenuBarMenu
                                    className={classNames(styles.menuBarMenu)}
                                    open={this.props.modeMenuOpen}
                                    place={this.props.isRtl ? 'left' : 'right'}
                                    onRequestClose={this.props.onRequestCloseMode}
                                >
                                    <MenuSection>
                                        <MenuItem onClick={this.handleSetMode('NOW')}>
                                            <span className={classNames({[styles.inactive]: !this.props.modeNow})}>
                                                {'✓'}
                                            </span>
                                            {' '}
                                            {this.props.intl.formatMessage(menuBarMessages.normalMode)}
                                        </MenuItem>
                                        <MenuItem onClick={this.handleSetMode('2020')}>
                                            <span className={classNames({[styles.inactive]: !this.props.mode2020})}>
                                                {'✓'}
                                            </span>
                                            {' '}
                                            {this.props.intl.formatMessage(menuBarMessages.modeMenuCaturday)}
                                        </MenuItem>
                                    </MenuSection>
                                </MenuBarMenu>
                            </div>
                        )}
                        
                        {/* hide setting menu with color mode submenu */}
                        {/* {(this.props.canChangeTheme || this.props.canChangeLanguage) && (<SettingsMenu
                            canChangeLanguage={this.props.canChangeLanguage}
                            canChangeTheme={this.props.canChangeTheme}
                            isRtl={this.props.isRtl}
                            onRequestClose={this.props.onRequestCloseSettings}
                            onRequestOpen={this.props.onClickSettings}
                            settingsMenuOpen={this.props.settingsMenuOpen}
                        />)} */}
                    </div>

                    <MenuItem onClick={this.props.onOpenBoardModal}>
                        <div className={classNames(styles.menuBarItem)}>
                            <img src={boardIcon} style={{height: '16px'}} draggable={false}/>
                            <span className={styles.collapsibleLabel}>
                                {this.props.intl.formatMessage(menuBarMessages.board)}
                            </span>
                        </div>
                    </MenuItem>

                    <MenuItem
                        onClick={async () => {
                            if (this.snackbarIds.serial) closeSnackbar(this.snackbarIds.serial);
                            this.snackbarIds.serial = enqueueSnackbar(this.props.intl.formatMessage(menuBarMessages.pairing), {
                                variant: 'info',
                                iconVariant: {
                                    info: <div style={{display: 'flex', marginRight: '10px'}}><CircularProgress size={20}/></div>,
                                },
                                autoHideDuration: null,
                            });
                            getSerialPort(()=>{}, this.props.board?.vendorId, false).then(() => {
                                if (this.snackbarIds.serial) closeSnackbar(this.snackbarIds.serial);
                                enqueueSnackbar(this.props.intl.formatMessage(menuBarMessages.paired), { variant: 'success', autoHideDuration: 2000 });
                            }).catch((e) => {
                                if (this.snackbarIds.serial) closeSnackbar(this.snackbarIds.serial);
                                enqueueSnackbar(this.props.intl.formatMessage(menuBarMessages.pairFailed), { variant: 'error', autoHideDuration: 2000 });
                                console.error(e);
                            });
                        }}
                    >
                        <div className={classNames(styles.menuBarItem)}>
                            
                            <img src={pairIcon} style={{height: '14px'}} draggable={false}/>
                            <span className={styles.collapsibleLabel}>
                                {this.props.intl.formatMessage(menuBarMessages.pair)}
                            </span>
                        </div>
                    </MenuItem>

                    <Divider className={classNames(styles.divider)} />

                    {this.props.canEditTitle ? (
                        <div className={classNames(styles.menuBarItem, styles.growable)}>
                            <MenuBarItemTooltip
                                enable
                                id="title-field"
                            >
                                <ProjectTitleInput
                                    className={classNames(styles.titleFieldGrowable)}
                                />
                            </MenuBarItemTooltip>
                        </div>
                    ) : ((this.props.authorUsername && this.props.authorUsername !== this.props.username) ? (
                        <AuthorInfo
                            className={styles.authorInfo}
                            imageUrl={this.props.authorThumbnailUrl}
                            projectTitle={this.props.projectTitle}
                            userId={this.props.authorId}
                            username={this.props.authorUsername}
                        />
                    ) : null)}

                    {(this.props.canShare) && (
                        <div className={classNames(styles.menuBarItem)}>
                            (this.props.isShowingProject || this.props.isUpdating) && (
                                <ProjectWatcher onDoneUpdating={this.props.onSeeCommunity}>
                                    {
                                        waitForUpdate => (
                                            <ShareButton
                                                className={styles.menuBarButton}
                                                isShared={this.props.isShared}
                                                /* eslint-disable react/jsx-no-bind */
                                                onClick={() => {
                                                    this.handleClickShare(waitForUpdate);
                                                }}
                                                /* eslint-enable react/jsx-no-bind */
                                            />
                                        )
                                    }
                                </ProjectWatcher>
                            )
                            {this.props.canRemix ? remixButton : []}
                        </div>
                    )}
                        

                    {(this.props.enableCommunity) && (
                        <div className={classNames(styles.menuBarItem, styles.communityButtonWrapper)}>
                            (this.props.isShowingProject || this.props.isUpdating) && (
                                <ProjectWatcher onDoneUpdating={this.props.onSeeCommunity}>
                                    {
                                        waitForUpdate => (
                                            <CommunityButton
                                                className={styles.menuBarButton}
                                                /* eslint-disable react/jsx-no-bind */
                                                onClick={() => {
                                                    this.handleClickSeeCommunity(waitForUpdate);
                                                }}
                                                /* eslint-enable react/jsx-no-bind */
                                            />
                                        )
                                    }
                                </ProjectWatcher>
                            )
                        </div>
                    )}

                    {/* additional download button in menu bar */}
                    {(!this.props.scratchMode && this.props.activeTabIndex === EDITOR_TAB_INDEX) ? (
                        <MenuItem
                            onClick={this.saveInoToComputerHandler}
                        >
                            <div className={classNames(styles.menuBarItem)}>
                                <img src={saveIcon} style={{height: '18px'}} draggable={false}/>
                            </div>
                        </MenuItem>
                    ):(
                        <SB3Downloader>{(className, downloadProjectCallback) => (
                            <MenuItem
                                className={className}
                                onClick={this.getSaveToComputerHandler(downloadProjectCallback)}
                            >
                                <div className={classNames(styles.menuBarItem)}>
                                    <img src={saveIcon} style={{height: '18px'}} draggable={false}/>
                                </div>
                                
                            </MenuItem>
                        )}</SB3Downloader>
                    )}

                    {/* hide tutorial menu */}
                    {/* <div className={styles.fileGroup}>
                        <div
                            aria-label={this.props.intl.formatMessage(ariaMessages.tutorials)}
                            className={classNames(styles.menuBarItem, styles.hoverable)}
                            onClick={this.props.onOpenTipLibrary}
                        >
                            <img
                                className={styles.helpIcon}
                                src={helpIcon}
                            />
                            <span className={styles.tutorialsLabel}>
                                <FormattedMessage {...ariaMessages.tutorials} />
                            </span>
                        </div>
                    </div> */}
                </div>

                {/* show the mode switching button UI in the menu bar */}
                <div className={styles.modeInfoButtonGroup}>
                    {/* <div style={{margin: '0 0.25rem'}}>
                        <ScratchMode>{(setScratchModeOn, setScratchModeOff, {scratchMode}) => (
                            <div className={classNames(
                                    styles.menuBarItem,
                                    styles.modeButton
                                )}>
                                <div><span>Mode</span></div>
                                <div className={styles.modeInfoButtonContainer}>
                                    <ToggleButtons
                                        buttons={[
                                            {
                                                handleClick: setScratchModeOn,
                                                text: "Scratch",
                                                title: "Scratch",
                                                isSelected: scratchMode,
                                            },
                                            {
                                                handleClick: setScratchModeOff,
                                                text: "Board",
                                                title: "Board",
                                                isSelected: !scratchMode,
                                            }
                                        ]}
                                        className={styles.modeInfoButton}
                                        // disabled={true}
                                    />
                                </div>
                            </div>
                        )}</ScratchMode>
                    </div> */}

                    
                    
                    <LanguageMenu
                        isRtl={this.props.isRtl}
                        isLanguageMenuOpen={this.props.languageMenuOpen}
                        openMenu={this.props.onClickLanguage}
                        closeMenu={this.props.onRequestCloseLanguage}
                    />

                    <Divider className={classNames(styles.divider)} />

                    {/* show the proper UI in the account menu, given whether the user is
                    logged in, and whether a session is available to log in with */}
                    {/* <div className={styles.menuBarItem}>
                        {this.props.canSave && (
                            <SaveStatus />
                        )}
                    </div> */}
                    {this.props.sessionExists ? (
                        this.props.username ? (
                            // ************ user is logged in ************
                            <React.Fragment>
                                <a href="/mystuff/">
                                    <div
                                        className={classNames(
                                            styles.menuBarItem,
                                            styles.hoverable,
                                            styles.mystuffButton
                                        )}
                                    >
                                        <img
                                            className={styles.mystuffIcon}
                                            src={mystuffIcon}
                                        />
                                    </div>
                                </a>
                                <AccountNav
                                    className={classNames(
                                        styles.menuBarItem,
                                        styles.hoverable,
                                        {[styles.active]: this.props.accountMenuOpen}
                                    )}
                                    isOpen={this.props.accountMenuOpen}
                                    isRtl={this.props.isRtl}
                                    menuBarMenuClassName={classNames(styles.menuBarMenu)}
                                    onClick={this.props.onClickAccount}
                                    onClose={this.props.onRequestCloseAccount}
                                    onLogOut={this.props.onLogOut}
                                />
                            </React.Fragment>
                        ) : (
                            // ********* user not logged in, but a session exists
                            // ********* so they can choose to log in
                            <React.Fragment>
                                <div
                                    className={classNames(
                                        styles.menuBarItem,
                                        styles.hoverable
                                    )}
                                    key="join"
                                    onMouseUp={this.props.onOpenRegistration}
                                >
                                    <FormattedMessage
                                        defaultMessage="Join Scratch"
                                        description="Link for creating a Scratch account"
                                        id="gui.menuBar.joinScratch"
                                    />
                                </div>
                                <div
                                    className={classNames(
                                        styles.menuBarItem,
                                        styles.hoverable
                                    )}
                                    key="login"
                                    onMouseUp={this.props.onClickLogin}
                                >
                                    <FormattedMessage
                                        defaultMessage="Sign in"
                                        description="Link for signing in to your Scratch account"
                                        id="gui.menuBar.signIn"
                                    />
                                    <LoginDropdown
                                        className={classNames(styles.menuBarMenu)}
                                        isOpen={this.props.loginMenuOpen}
                                        isRtl={this.props.isRtl}
                                        renderLogin={this.props.renderLogin}
                                        onClose={this.props.onRequestCloseLogin}
                                    />
                                </div>
                            </React.Fragment>
                        )
                    ) : (
                        // ******** no login session is available, so don't show login stuff
                        <React.Fragment>
                            {this.props.showComingSoon ? (
                                <React.Fragment>
                                    {/* hide stuff bag */}
                                    {/* <MenuBarItemTooltip id="mystuff">
                                        <div
                                            className={classNames(
                                                styles.menuBarItem,
                                                styles.hoverable,
                                                styles.mystuffButton
                                            )}
                                        >
                                            <img
                                                className={styles.mystuffIcon}
                                                src={mystuffIcon}
                                            />
                                        </div>
                                    </MenuBarItemTooltip> */}
                                    <MenuBarItemTooltip
                                        id="account-nav"
                                        place={this.props.isRtl ? 'right' : 'left'}
                                    >
                                        <div
                                            className={classNames(
                                                styles.menuBarItem,
                                                styles.hoverable,
                                                styles.accountNavMenu
                                            )}
                                        >
                                            <img
                                                className={styles.profileIcon}
                                                src={profileIcon}
                                                draggable={false}
                                            />
                                            <span>
                                                {this.props.intl.formatMessage(menuBarMessages.blueinnoStudent)}
                                            </span>
                                            <img
                                                className={styles.dropdownCaretIcon}
                                                src={dropdownCaret}
                                            />
                                        </div>
                                    </MenuBarItemTooltip>
                                </React.Fragment>
                            ) : []}
                        </React.Fragment>
                    )}
                </div>

                {aboutButton}

                {/* AlertDialog for convert button */}
                <AlertDialog 
                    open={this.state.isOpenConvertDialog}
                    title={this.props.intl.formatMessage(menuBarMessages.convertProjectDialogTitle)}
                    content={this.props.intl.formatMessage(menuBarMessages.convertProjectDialogContent)}
                    handleClickContinue={(e) => {
                        this.handleConvert(e);
                        this.handleClickConvert(false);
                    }}
                    handleClickCancel={() => this.handleClickConvert(false)}
                />
            </Box>
        );
    }
}

MenuBar.propTypes = {
    aboutMenuOpen: PropTypes.bool,
    activeTabIndex: PropTypes.number,
    accountMenuOpen: PropTypes.bool,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    autoUpdateProject: PropTypes.func,
    canChangeLanguage: PropTypes.bool,
    canChangeTheme: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    className: PropTypes.string,
    confirmReadyToReplaceProject: PropTypes.func,
    currentLocale: PropTypes.string.isRequired,
    editMenuOpen: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    fileMenuOpen: PropTypes.bool,
    languageMenuOpen: PropTypes.bool,
    intl: intlShape,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isShowingExamples: PropTypes.bool,
    isShowingProject: PropTypes.bool,
    isTotallyNormal: PropTypes.bool,
    isUpdating: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    loginMenuOpen: PropTypes.bool,
    logo: PropTypes.string,
    mode1920: PropTypes.bool,
    mode1990: PropTypes.bool,
    mode2020: PropTypes.bool,
    mode220022BC: PropTypes.bool,
    modeMenuOpen: PropTypes.bool,
    modeNow: PropTypes.bool,
    onClickAbout: PropTypes.oneOfType([
        PropTypes.func, // button mode: call this callback when the About button is clicked
        PropTypes.arrayOf( // menu mode: list of items in the About menu
            PropTypes.shape({
                title: PropTypes.string, // text for the menu item
                onClick: PropTypes.func // call this callback when the menu item is clicked
            })
        )
    ]),
    onClickAccount: PropTypes.func,
    onClickEdit: PropTypes.func,
    onClickFile: PropTypes.func,
    onClickLogin: PropTypes.func,
    onClickLogo: PropTypes.func,
    onClickMode: PropTypes.func,
    onClickNew: PropTypes.func,
    onClickRemix: PropTypes.func,
    onClickSave: PropTypes.func,
    onClickSaveAsCopy: PropTypes.func,
    onClickSettings: PropTypes.func,
    onClickLanguage: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenBoardModal: PropTypes.func,
    openExampleLibrary: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onOpenTipLibrary: PropTypes.func,
    onProjectTelemetryEvent: PropTypes.func,
    onRequestCloseAbout: PropTypes.func,
    onRequestCloseAccount: PropTypes.func,
    onRequestCloseEdit: PropTypes.func,
    onRequestCloseFile: PropTypes.func,
    onRequestCloseLogin: PropTypes.func,
    onRequestCloseMode: PropTypes.func,
    onRequestCloseSettings: PropTypes.func,
    onRequestOpenAbout: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onSetTimeTravelMode: PropTypes.func,
    onShare: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    projectTitle: PropTypes.string,
    renderLogin: PropTypes.func,
    sessionExists: PropTypes.bool,
    settingsMenuOpen: PropTypes.bool,
    shouldSaveBeforeTransition: PropTypes.func,
    showEditMenu: PropTypes.bool,
    showComingSoon: PropTypes.bool,
    scratchMode: PropTypes.bool,
    username: PropTypes.string,
    userOwnsProject: PropTypes.bool,
    vm: PropTypes.instanceOf(VM).isRequired,
    codeEditor: PropTypes.object,
    board: PropTypes.object,
};

MenuBar.defaultProps = {
    logo: blueinnoLogo,
    onShare: () => {},
};

const mapStateToProps = (state, ownProps) => {
    const loadingState = state.scratchGui.projectState.loadingState;
    const user = state.session && state.session.session && state.session.session.user;
    return {
        aboutMenuOpen: aboutMenuOpen(state),
        accountMenuOpen: accountMenuOpen(state),
        currentLocale: state.locales.locale,
        fileMenuOpen: fileMenuOpen(state),
        languageMenuOpen: languageMenuOpen(state),
        editMenuOpen: editMenuOpen(state),
        isRtl: state.locales.isRtl,
        isUpdating: getIsUpdating(loadingState),
        isShowingExamples: false,
        isShowingProject: getIsShowingProject(loadingState),
        locale: state.locales.locale,
        loginMenuOpen: loginMenuOpen(state),
        modeMenuOpen: modeMenuOpen(state),
        projectTitle: state.scratchGui.projectTitle,
        sessionExists: state.session && typeof state.session.session !== 'undefined',
        settingsMenuOpen: settingsMenuOpen(state),
        username: user ? user.username : null,
        userOwnsProject: ownProps.authorUsername && user &&
            (ownProps.authorUsername === user.username),
        vm: state.scratchGui.vm,
        mode220022BC: isTimeTravel220022BC(state),
        mode1920: isTimeTravel1920(state),
        mode1990: isTimeTravel1990(state),
        mode2020: isTimeTravel2020(state),
        modeNow: isTimeTravelNow(state),
        codeEditor: state.scratchGui.editorTab.editor,
        board: state.scratchGui.board,
    };
};

const mapDispatchToProps = dispatch => ({
    autoUpdateProject: () => dispatch(autoUpdateProject()),
    onOpenBoardModal: () => dispatch(openBoardModal()),
    onOpenExampleLibrary: () => dispatch(openExampleLibrary()),
    onOpenTipLibrary: () => dispatch(openTipsLibrary()),
    onClickAccount: () => dispatch(openAccountMenu()),
    onRequestCloseAccount: () => dispatch(closeAccountMenu()),
    onClickFile: () => dispatch(openFileMenu()),
    onRequestCloseFile: () => dispatch(closeFileMenu()),
    onClickEdit: () => dispatch(openEditMenu()),
    onRequestCloseEdit: () => dispatch(closeEditMenu()),
    onClickLogin: () => dispatch(openLoginMenu()),
    onRequestCloseLogin: () => dispatch(closeLoginMenu()),
    onClickMode: () => dispatch(openModeMenu()),
    onRequestCloseMode: () => dispatch(closeModeMenu()),
    onRequestOpenAbout: () => dispatch(openAboutMenu()),
    onRequestCloseAbout: () => dispatch(closeAboutMenu()),
    onClickSettings: () => dispatch(openSettingsMenu()),
    onRequestCloseSettings: () => dispatch(closeSettingsMenu()),
    onClickLanguage: () => dispatch(openLanguageMenu()),
    onRequestCloseLanguage: () => dispatch(closeLanguageMenu()),
    onClickNew: needSave => dispatch(requestNewProject(needSave)),
    onClickRemix: () => dispatch(remixProject()),
    onClickSave: () => dispatch(manualUpdateProject()),
    onClickSaveAsCopy: () => dispatch(saveProjectAsCopy()),
    onSeeCommunity: () => dispatch(setPlayer(true)),
    onSetTimeTravelMode: mode => dispatch(setTimeTravel(mode)),
    onActivateEditorTab: () => dispatch(activateTab(EDITOR_TAB_INDEX)),
});

export default compose(
    injectIntl,
    MenuBarHOC,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(MenuBar);
