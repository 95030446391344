
// var Avrgirl = require('avrgirl-arduino');
import AvrgirlArduino from './avrgirl-arduino.js';

/**
 * get hex map of code from compilation server
 * @param {string} code code to compile 
 * @returns {Array.<boolean, string, string>} status, message, hex 
 */
const getHexMap = async(fqbn, code) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            "fqbn": fqbn,
            "version": "v2",
            'data_string': code 
        })
    };
    const response = await fetch('/compile', requestOptions);
    const response_text = await response.text();

    try {
        const response_json = JSON.parse(response_text); // Try to parse the response as JSON
        return {
            'status': response_json['compileStatus'],
            'message': response_json['compilationErrLog'],
            'hex': response_json['compiledCode']
        }
    } catch(err) {
        return {
            'status': false,
            'message': err,
            'hex': ''
        }
    }
}

/**
 * flash board with hex code
 * @param {string} hex hex code to flash
 */
const flashBoard = async(boardName, hex, callback) => {
    let encoder = new TextEncoder();
    var encoded_code = encoder.encode(hex);
    var avrgirl = new AvrgirlArduino({
        board: boardName,
        autoSelect: true,
        // debug: true
    });

    avrgirl.flash(encoded_code, function (error) {
        if (error) {
            callback(false, error);
            throw error;
        }
        callback(true);
    });
}

export {
    getHexMap,
    flashBoard,
};
