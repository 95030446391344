import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import {FormattedMessage, injectIntl, intlShape} from 'react-intl';

import Box from '../box/box.jsx';
import Modal from '../../containers/modal.jsx';
import { Button, Card, Col, Row } from 'antd';

import styles from './example-modal.css';

import exampleModalMessages from './example-modal-messages';

const { Meta } = Card;

class ExampleModalComponent extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'getFilteredData',
        ]);
    }

    getFilteredData () {
        return this.props.data.filter(dataItem => {
            // for future filtering use, e.g. search bar, tags, etc.
            return true;
        });
    }

    render () {
        return (
            <Modal
                id="exampleModal"
                className={styles.modalContent}
                headerClassName={styles.header}
                contentLabel={this.props.intl.formatMessage(exampleModalMessages.title)}
                onRequestClose={this.props.onCancel}
            >
                <Box className={styles.body}>
                    <Box className={styles.container}>
                        <Row gutter={[16, 16]}>
                            {
                                this.getFilteredData().map((dataItem, index) => (
                                    <Col key={index.toString()} span={6}>
                                        <div onClick={()=>this.props.onChooseExample(dataItem.urlId, dataItem.type)}>
                                            <Card
                                                hoverable
                                                bordered={false}
                                                style={{
                                                    width: 200,
                                                }}
                                                cover={<img alt="example" src={dataItem.img} />}
                                            >
                                                <Meta title={dataItem.name} />
                                            </Card>
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Box>
                </Box>
            </Modal>
        );
    }
};

ExampleModalComponent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCancel: PropTypes.func.isRequired,
    onChooseExample: PropTypes.func.isRequired,
    intl: intlShape,
};

export default injectIntl(ExampleModalComponent);