import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';

import Box from '../box/box.jsx';
import Selector from './selector.jsx';
import styles from './asset-panel.css';

const AssetPanel = forwardRef((props, ref) => (
    <Box 
        className={styles.wrapper}
        componentRef={ref}
        style={props.style}
    >
        {props.isSelector ? (
            <Selector
                className={styles.selector}
                {...props}
            />
            ) : null
        }
        <Box 
            className={props.isSelector ? styles.detailArea : ''}
        >
            {props.children}
        </Box>
    </Box>
));

AssetPanel.propTypes = {
    isSelector: PropTypes.bool,
    style: stylePropType,
    ...Selector.propTypes
};

AssetPanel.defaultProps = {
    isSelector: true,
    style: {},
};

export default AssetPanel;
