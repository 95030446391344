import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import locales from 'scratch-l10n';

import check from './icon--check.svg';

import {MenuItem} from '../menu/menu.jsx';
import MenuBarMenu from '../menu-bar/menu-bar-menu.jsx';

import {selectLocale} from '../../reducers/locales.js';

import menuBarStyles from '../menu-bar/menu-bar.css';
import styles from './language-menu-item.css';

class LanguageMenuItems extends React.PureComponent {
    constructor (props) {
        super(props);
        bindAll(this, [
            'setRef',
            'handleMouseOver'
        ]);
    }

    componentDidUpdate (prevProps) {
        // If the submenu has been toggled open, try scrolling the selected option into view.
        if (!prevProps.isMenuOpen && this.props.isMenuOpen && this.selectedRef) {
            this.selectedRef.scrollIntoView({block: 'center'});
        }
    }

    setRef (component) {
        this.selectedRef = component;
    }

    handleMouseOver () {
        // If we are using hover rather than clicks for submenus, scroll the selected option into view
        if (!this.props.isMenuOpen && this.selectedRef) {
            this.selectedRef.scrollIntoView({block: 'center'});
        }
    }

    render () {
        return (
            <MenuBarMenu
                className={classNames(
                    menuBarStyles.menuBarMenu,
                    styles.languageMenu,
                    {
                        [styles.left]: !this.props.isRtl,
                        [styles.right]: this.props.isRtl
                    }
                )}
                open={this.props.isMenuOpen}
                onRequestClose={this.props.closeMenu}
            >
                {
                    Object.keys(locales)
                        .map(locale => (
                            <MenuItem
                                key={locale}
                                className={styles.languageMenuItem}
                                // eslint-disable-next-line react/jsx-no-bind
                                onClick={() => this.props.onChangeLanguage(locale)}
                            >
                                <img
                                    className={classNames(styles.check, {
                                        [styles.selected]: this.props.currentLocale === locale
                                    })}
                                    src={check}
                                    {...(this.props.currentLocale === locale && {ref: this.setRef})}
                                />
                                {locales[locale].name}
                            </MenuItem>
                        ))
                }
            </MenuBarMenu>
        );
    }
}

LanguageMenuItems.propTypes = {
    currentLocale: PropTypes.string,
    isRtl: PropTypes.bool,
    isMenuOpen: PropTypes.bool,
    closeMenu: PropTypes.func,
    onChangeLanguage: PropTypes.func,
};

const mapStateToProps = state => ({
    currentLocale: state.locales.locale,
    isRtl: state.locales.isRtl,
    messagesByLocale: state.locales.messagesByLocale
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onChangeLanguage: locale => {
        dispatch(selectLocale(locale));
        ownProps.closeMenu();
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LanguageMenuItems);
