import React from 'react';

import { SnackbarProvider, closeSnackbar } from 'notistack';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import styles from './snack-bar.css';

const SnackBarComponent = () => (
    <SnackbarProvider 
        maxSnack={5}
        autoHideDuration={3000}
        TransitionProps={{ direction: 'left' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        action={
            // Close button for each snackbar
            (snackbarId) => (
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        closeSnackbar(snackbarId);
                    }}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            )
        }
    />
);

export default SnackBarComponent;