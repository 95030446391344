const SET_COMPILATION_STATE = 'scratch-gui/boards/SET_COMPILATION_STATE';

const BOARD_ARDUINO_UNO = {
    name: 'uno',
    fqbn: 'arduino:avr:uno',
    vendorId: ['0x2341'],
};
const BOARD_ARDUINO_NANO = {
    name: 'nano (new bootloader)',
    fqbn: 'arduino:avr:nano',
    vendorId: ['0x1a86'],
};

const initialState = BOARD_ARDUINO_UNO;

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_COMPILATION_STATE:
        return Object.assign({}, state, action.board);
    default:
        return state;
    }
};

const setCompilationState = function (board) {
    return {
        type: SET_COMPILATION_STATE,
        board: board
    };
};


export {
    reducer as default,
    initialState as boardInitialState,
    setCompilationState,
    BOARD_ARDUINO_UNO,
    BOARD_ARDUINO_NANO,
};