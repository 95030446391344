import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import uploadButtonIcon from './icon--upload-button.svg';
import styles from './upload-button.css';

const UploadButtonComponent = function (props) {
    const {
        active,
        className,
        onClick,
        title,
        disabled,
        ...componentProps
    } = props;

    return (
        <div className={styles.container}>
            {active ? <CircularProgress size={28} className={classNames(styles.fabProgress)} /> : null}
            {disabled ? <div className={styles.isDisabled} /> : null}
            <img
                className={classNames(
                    className,
                    styles.uploadButton,
                    {
                        [styles.isActive]: active,
                    }
                )}
                draggable={false}
                src={uploadButtonIcon}
                title={title}
                onClick={onClick}
                {...componentProps}
            />
        </div>
    );
};

UploadButtonComponent.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
    disabled: PropTypes.bool
};

UploadButtonComponent.defaultProps = {
    active: false,
    title: 'Upload',
    disabled: false
};

export default UploadButtonComponent;